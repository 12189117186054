import * as React from "react";
import { Link } from "gatsby";
import { getEventAttributes, EventCategory } from "../../../utils/analytics";
import Image from "../image/image";
import styles from "./question-card.styles";
import QuoteMark from "./quote-mark.svg";

interface QuestionCardProps {
  name: string;
  url: string;
  eventCategory: EventCategory;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  name,
  url,
  eventCategory,
}) => {
  return (
    <Link
      to={url}
      css={styles.root}
      {...getEventAttributes({
        category: eventCategory,
        action: "Click - Question card",
        label: name,
      })}
    >
      <Image src={QuoteMark} alt={`"`} />
      <div css={styles.heading}>{name}</div>
    </Link>
  );
};

export default QuestionCard;
