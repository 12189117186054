import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FeaturedBlockchainIntentsQuery } from "../../../../graphql-types";
import Section from "../../ui-library/section/section";
import Row from "../../ui-library/column-system/row";
import Column from "../../ui-library/column-system/column";
import QuestionCard from "../../ui-library/question-card/question-card";
import { EventCategory } from "../../../utils/analytics";

const FeaturedBlockchainQuestions: React.FC = () => {
  const data = useStaticQuery<FeaturedBlockchainIntentsQuery>(
    graphql`
      query FeaturedBlockchainIntents {
        askAboutQuery {
          blockchainFeaturedIntents {
            alias
            intentName
            displayName
            displayOrder
          }
        }
      }
    `
  );

  const questions = data.askAboutQuery.blockchainFeaturedIntents;

  if (!questions) {
    return null;
  }

  return (
    <Section heading="Frequently asked blockchain questions">
      <Row>
        {questions.map((q) => (
          <Column key={q.alias} spanLg={6} spanMd={6} spanSm={12}>
            <QuestionCard
              name={q.displayName}
              url={`/blockchain/ask/${q.alias}/`}
              eventCategory={EventCategory.Section_MostAsked}
            />
          </Column>
        ))}
      </Row>
    </Section>
  );
};

export default FeaturedBlockchainQuestions;
