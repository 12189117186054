import { Interpolation } from "@emotion/core";
import { Device } from "../../design-tokens/media-queries";
import { grid } from "../../design-tokens/grid";
import { Fonts } from "./../../design-tokens/fonts";

const root: Interpolation = {
  marginTop: grid(3.5),
  marginBottom: grid(3.5),

  [Device.DesktopTablet]: {
    marginTop: grid(4),
    marginBottom: grid(4),
  },
};

const alignRight: Interpolation = {
  textAlign: "right",
};

const heading: Interpolation = {
  fontWeight: Fonts.Weight.SemiBold,
  marginBottom: grid(2),
};

const styles = {
  root,
  alignRight,
  heading,
};

export default styles;
