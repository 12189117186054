import * as React from "react";
import styles from "./section.styles";

interface SectionProps {
  heading?: string;
  align?: "left" | "right";
}

const Section: React.FC<SectionProps> = ({
  heading,
  align = "left",
  children,
}) => {
  return (
    <section css={[styles.root, align !== "left" && styles.alignRight]}>
      {!!heading && <h2 css={styles.heading}>{heading}</h2>}

      {children}
    </section>
  );
};

export default Section;
