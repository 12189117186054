import { Interpolation } from "@emotion/core";
import { Fonts } from "../../design-tokens/fonts";
import { Colors } from "../../design-tokens/colors";
import { Border } from "../../design-tokens/borders";
import { grid } from "../../design-tokens/grid";
import { Keyframes } from "../../design-tokens/animation";
import { DarkTheme, Device } from "../../design-tokens/media-queries";
import { BoxShadow } from "../../design-tokens/box-shadow";

const root: Interpolation = {
  position: "relative",
  display: "flex",
  alignItems: "flex-start",
  minHeight: grid(11),
  backgroundColor: Colors.White,
  padding: grid(2),
  borderRadius: Border.RadiusLg,
  boxShadow: BoxShadow.Default,
  transition: "all 0.2s ease",
  animation: `${Keyframes.FadeIn} 0.3s linear`,
  overflow: "hidden",

  [DarkTheme]: {
    backgroundColor: Colors.DarkModeBackground,
  },

  [Device.DesktopTablet]: {
    paddingRight: grid(5.5),
  },

  "> img": {
    width: 30,
    marginRight: grid(1.5),

    [Device.Mobile]: {
      width: 24,
      marginRight: grid(1),
    },
  },

  "&:hover": {
    textDecoration: "none",
    transform: "translateY(-3px)",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)",
  },
};

const heading: Interpolation = {
  color: Colors.Black,
  fontSize: "20px",
  lineHeight: "28px",
  fontWeight: Fonts.Weight.Bold,

  [DarkTheme]: {
    color: Colors.DarkModeWhite,
  },
};

const styles = {
  root,
  heading,
};

export default styles;
