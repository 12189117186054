import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import socialImage from "../../images/ask-about-social.jpg";
import logoImage from "../../images/favicon.png";

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: {
    name: string;
    content: string;
  }[];
  title: string;
  imageUrl?: string;
}

const SEO: React.FC<SEOProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title,
  imageUrl,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const logoSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://askabout.io",
    logo: logoImage,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl || `https://askabout.io${socialImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:image`,
          content: imageUrl || `https://askabout.io${socialImage}`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(logoSchema)}</script>
    </Helmet>
  );
};

export default SEO;
