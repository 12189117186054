import * as React from "react";
import Column from "../../ui-library/column-system/column";
import Row from "../../ui-library/column-system/row";
import Section from "../../ui-library/section/section";
import QuestionLink from "../../ui-library/question-link/question-link";
import { EventCategory } from "../../../utils/analytics";
import { useBlockchainIntentListQuery } from "../../../hooks/static-queries/use-blockchain-intent-list-query";

const RecentlyAskedQuestions: React.FC = () => {
  const intents = useBlockchainIntentListQuery();

  if (!intents || intents.length < 4) {
    return null;
  }

  const selectedIndexes: number[] = [];

  const chooseIntentIndex = (): number => {
    const randomIndex = Math.floor(Math.random() * intents.length);
    if (selectedIndexes.indexOf(randomIndex) === -1) {
      return randomIndex;
    }
    return chooseIntentIndex();
  };

  for (let i = 0; i < 4; i++) {
    const r = chooseIntentIndex();
    selectedIndexes.push(r);
  }

  const chosenIntents = selectedIndexes.map((i) => intents[i]);

  return (
    <Section heading="You might like to ask">
      <Row>
        {chosenIntents.map((q) => (
          <Column key={q.alias} spanLg={6} spanMd={6} spanSm={12}>
            <QuestionLink
              to={`/blockchain/ask/${q.alias}/`}
              eventCategory={EventCategory.Section_RandomIntents}
            >
              {q.displayName}
            </QuestionLink>
          </Column>
        ))}
      </Row>
    </Section>
  );
};

export default RecentlyAskedQuestions;
