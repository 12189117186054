import * as React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import Container from "../../components/ui-library/container/container";
import { LogoBunny } from "../../components/site/header/header";
import { Topic } from "../../types/topics";
import RecentlyAskedQuestions from "../../components/blockchain/recently-asked-questions/recently-asked-questions";
import FeaturedBlockchainQuestions from "../../components/blockchain/featured-blockchain-questions/featured-blockchain-questions";
import socialImage from "../../images/ask-about-social-blockchain.jpg";

const BlockchainPage: React.FC = () => (
  <Layout
    topic={Topic.Blockchain}
    description="digital economy"
    headerUrl="/blockchain/"
    logoBunny={LogoBunny.Main}
    isTopicHomePage={true}
  >
    <SEO
      title="Blockchain technology"
      description="Get quick answers around blockchain technology."
      imageUrl={socialImage}
    />

    <Container>
      <RecentlyAskedQuestions />
    </Container>

    <Container>
      <FeaturedBlockchainQuestions />
    </Container>
  </Layout>
);

export default BlockchainPage;
